body {
  position: relative;
}

html,
body {
  height: 100%;
}

root { padding: 0 !important; }

.k-drawer-container {
  position: fixed;
  width: 100%;
  height: 100%;
}
.k-drawer-item {
  user-select: none;
}
.k-icon {
  font-size: 20px;
}
.application-toolbar {
  display: flex;
  width: 100%;
  background-color: #f6f6f6;
  line-height: 10px;
  border-bottom: inset;
  border-bottom-width: 1px;
  padding: 3px 8px;
  color: #656565;
}
.mail-box{
  margin-left: 20px;
  font-weight: bold;
  font-size: 17px;
}
